@import "../../../styles/base/abstracts";

.hbs-program-finder__inner {
  @include padding((#{$grid-padding-mobile * 4}) #{$grid-padding-mobile * 2});
  background-color: $c-bg;

  @include mq($bp-desktop) {
    @include padding(#{$grid-padding * 3} #{$grid-padding * 2});
  }
}

.hbs-program-finder__hgroup {
  display: flex;
  flex-direction: column;

  @include mq($bp-tablet-xl) {
    width: $col-8;
  }
}

.hbs-program-finder__title {
  @include h3($font-weight: $fw-reg);
}

.hbs-program-finder__subtitle {
  @include h3($font-weight: $fw-reg);
  color: $c-text-lighter;
}

.hbs-program-finder__footer {
  @include baseline-text;
  color: $c-text-light;
  margin-top: $spacing-lg;

  a {
    @include link(
      $text-color: inherit,
      $text-hover-color: $c-text,
      $underline-color: inherit
    );
  }
}

.hbs-program-finder__form {
  margin-top: $spacing-xxl;

  @include mq($bp-desktop) {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
  }

  > * {
    @include mq(max, $bp-desktop) {
      border-radius: $border-radius;

      &:nth-child(-n + 2) {
        margin-bottom: $spacing-md;
      }
    }

    @include mq($bp-desktop) {
      margin: 0;

      &:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      &:nth-child(3) {
        border-right: none;
      }
    }
  }

  .hbs-program-finder__input,
  .hbs-program-finder__dropdown {
    @include body-text($ff-sans);
    background-color: $c-button-bg-subtle;
    border: $border;
    position: relative;

    @include mq($bp-desktop) {
      flex-grow: 1;
    }
  }

  .hbs-program-finder__input {
    display: flex;
    align-items: center;
    @include mq($bp-desktop) {
      min-width: 40%;
    }

    .hbs-program-finder__input-icon {
      @include size(1em);
      @include margin($spacing-sm);

      @include mq($bp-desktop) {
        margin-left: $spacing-sm + 5px;
      }
    }

    input {
      @include padding(x $spacing-md x x);
      @include size(100%);
      text-overflow: ellipsis;

      &::placeholder {
        color: $c-text-lighter;
        transition: $transition;
      }

      @include mq(max, $bp-desktop) {
        @include padding($spacing-sm);
        padding-left: unset;
      }

      &:focus {
        border-color: $outline;

        &::placeholder {
          color: $c-text;
        }
      }
    }
  }

  .hbs-program-finder__dropdown {
    position: relative;

    @include mq($bp-desktop) {
      border-left: none;
      width: 10%;
    }
  }

  .hbs-program-finder__dropdown-trigger {
    @include size(100%);
    @include padding(x $spacing-md);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &[data-state="open"] {
      .hbs-program-finder__dropdown-caret {
        transform: rotateX(180deg);
      }
    }

    @include mq(max, $bp-desktop) {
      @include padding($spacing-sm);
    }
  }

  .hbs-program-finder__dropdown-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .hbs-program-finder__dropdown-caret {
    @include size(0.8em);
    min-width: 0.8em;
    transition: $transition;
    margin-left: $spacing-xs;
  }

  .hbs-program-finder__submit {
    @include mq(max, $bp-desktop) {
      justify-content: center;
      margin-top: $spacing-xxl;
      width: 100%;
    }

    @include mq($bp-desktop) {
      @include padding($spacing-md);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      white-space: nowrap;
      height: 100%;
      border: $border;
      border-left: none;
    }

    [data-theme="black"] &,
    [data-theme="dark"] & {
      --c-spot: #{$c-crimson};
      color: $c-text;

      @include hover {
        color: $c-bg;
      }
    }
  }
}

.hbs-program-finder__dropdown-content {
  @include absolute(110% x x 0);
  @include padding($spacing-md);
  background-color: $c-bg;
  border: $border-light;
  border-radius: $border-radius;
  color: $c-text-light;
  width: var(--radix-popover-trigger-width);
  box-shadow: $box-shadow;

  @include mq-reduced-motion {
    animation: showUp $t-time $t-ease-out forwards;
    opacity: 0;
    transition: opacity $transition;
  }
}

[data-radix-popper-content-wrapper] {
  z-index: $z-index-1 !important;
}

.hbs-program-finder__dropdown-item {
  align-items: flex-start;
  display: flex;

  &:not(:last-child) {
    margin-bottom: $spacing-xs;

    @include mq($bp-desktop) {
      margin-bottom: $spacing-sm;
    }
  }
}

.hbs-program-finder__checkbox {
  @include checkbox-box;
  margin-top: 2px;

  &[data-state="checked"] {
    @include checkbox-checked;
  }
}

.hbs-program-finder__checkbox-icon {
  @include checkbox-icon;
}

.hbs-program-finder__checkbox-label {
  @include checkbox-label($spacing: $spacing-xs);
}
